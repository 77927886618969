
import { detectSubDomain } from "@/core/helpers/helps";
import router from "@/router";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "profile-detail",
  setup(prop, context) {
    const { t } = useI18n();
    const approvers = ref([]);
    const lists: any = ref([]);
    const drag = ref(false);
    const store = useStore();

    const route = useRoute();
    const isEdit = route.name == "profile-edit" ? '2px solid  #ABB3C0 !importa' : 'none';
    // const form = ref({
    //   name: "",
    //   username:"",
    //   email: "",
    //   tlp: "",
    //   industrial_sector: "",
    //   company_name: "",
    //   company_address: "",
    //   position: ""
    // });

    const form = computed(() => {
      return store.getters.getformProfile;
    });

    const getChannel = computed(() => {
      return store.getters.getListChannel;
    });
    const bumnSelected: any = ref([])

    onMounted(() => {
      store.dispatch(Actions.GET_PROFILE_DETAIL).then((res) => {
        form.value.nip = res.nip
        form.value.name = res.name;
        form.value.username = res.username;
        form.value.email = res.email;
        form.value.phone = res.phone;
        form.value._id = res._id
        bumnSelected.value = res.tenants[0].workspaces[0].channels
      })
    });

    const getChangePhone = () => {
      // if(form.value.tlp.match(/^(?:-?\d+)?$/)) {
      //   form.value.tlp = 
      // }
    }

    watch(
      () => form.value.tlp,
      (after, before) => {
        if (after?.match(/^-\d*\.?\d+$/)) {
          form.value.tlp = after.split("-")[1]
        }
      }
    );

    const EditProfile = route => {
      if (route == "profile-detail") {
        router.push({ name: "profile-edit" })
      }
    }

    const changePsw = () => {
      router.push({ 
        path: '/profile/password'
       })
    }

    const getProfile = computed(() => {
      return store.getters.getUserProfile;
    });

    return {
      getChangePhone,
      EditProfile,
      route,
      form,
      getChannel,
      bumnSelected,
      changePsw,
      t,
      getProfile
    };
  },
});
