import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3846acd7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card border-secondary border p-5" }
const _hoisted_2 = { class: "wrap-name my-5 d-flex align-items-center" }
const _hoisted_3 = { class: "image-upload" }
const _hoisted_4 = { class: "profile-image" }
const _hoisted_5 = { class: "mx-5" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-xl-6" }
const _hoisted_9 = { class: "text-primary" }
const _hoisted_10 = { class: "my-5 mb-8" }
const _hoisted_11 = { class: "my-5 mb-8" }
const _hoisted_12 = { class: "my-5 mb-8" }
const _hoisted_13 = { class: "my-5 mb-8" }
const _hoisted_14 = { class: "col-xl-6" }
const _hoisted_15 = { class: "text-primary" }
const _hoisted_16 = { class: "my-5 mb-8" }
const _hoisted_17 = { class: "my-5 mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.form.name.replace(" ", "").charAt(0)), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.form.username), 1)
        ]),
        _createElementVNode("div", null, _toDisplayString(_ctx.form.name), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.form.tlp), 1),
        (_ctx.getProfile.role._id !== '6321da2b36f0780907611cfa')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              (_ctx.route.name == 'profile-detail')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    ref: "cancelBtnRef",
                    type: "reset",
                    class: "btn btn-outline btn-outline-primary my-2",
                    "data-bs-dismiss": "modal",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.EditProfile(_ctx.route.name)))
                  }, " Edit Profil ", 512))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h3", _hoisted_9, _toDisplayString(_ctx.$t('signUpPage.personalData')), 1),
        _createVNode(_component_el_form, { model: _ctx.form }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_el_form_item, {
                class: "mb-1 d-block fw-bold",
                prop: "name",
                label: _ctx.t('signUpPage.formLabelName')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    disabled: _ctx.route.name == 'profile-edit' ? false : true,
                    modelValue: _ctx.form.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name) = $event)),
                    type: "text"
                  }, null, 8, ["disabled", "modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_el_form_item, {
                class: "mb-1 d-block fw-bold",
                prop: "username",
                label: _ctx.$t('signUpPage.formLabelUsername')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    disabled: _ctx.route.name == 'profile-edit' ? false : true,
                    modelValue: _ctx.form.username,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.username) = $event)),
                    type: "text"
                  }, null, 8, ["disabled", "modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_el_form_item, {
                class: "mb-1 d-block fw-bold",
                prop: "email",
                label: _ctx.$t('signUpPage.formLabelEmail')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    disabled: "",
                    modelValue: _ctx.form.email,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.email) = $event)),
                    type: "text"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_el_form_item, {
                class: "mb-1 d-block fw-bold",
                prop: "tlp",
                label: _ctx.$t('signUpPage.formLabelPhone')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    maxlength: "20",
                    onChange: _ctx.getChangePhone,
                    disabled: _ctx.route.name == 'profile-edit' ? false : true,
                    modelValue: _ctx.form.phone,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.phone) = $event)),
                    type: "number"
                  }, null, 8, ["onChange", "disabled", "modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("h3", _hoisted_15, _toDisplayString(_ctx.$t('signUpPage.agency')), 1),
        _createVNode(_component_el_form, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_el_form_item, {
                class: "mb-1 d-block fw-bold",
                prop: "text",
                label: "BUMN"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    disabled: _ctx.route.name == 'profile-edit' ? false : true,
                    modelValue: _ctx.bumnSelected[0].name,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.bumnSelected[0].name) = $event)),
                    type: "text"
                  }, null, 8, ["disabled", "modelValue"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_el_form_item, {
                class: "mb-1 d-block fw-bold",
                prop: "nip",
                label: _ctx.$t('signUpPage.formLabelNIP')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    disabled: _ctx.route.name == 'profile-edit' ? false : true,
                    modelValue: _ctx.form.nip,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.nip) = $event)),
                    type: "text"
                  }, null, 8, ["disabled", "modelValue"])
                ]),
                _: 1
              }, 8, ["label"])
            ])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("span", {
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.changePsw())),
        class: "link-change-psw"
      }, _toDisplayString(_ctx.t('changePasswordPage.changePasswordText')), 1)
    ])
  ]))
}